import apiTool from "./index";
import ApiService from "./ApiService";

const GET_SPECIFIC_CHAPITRE = "api/v1/chapitre/<pk>";
const LIST_CHAPITRES = "api/v1/chapitre/list/"
const DELETE_CHAPITRES = "api/v1/chapitre/delete/";
const LIST_REMOTE = "api/v1/chapitre/<pk>/list-remote-participants/"
const CHAPITRE_TYPES = "api/v1/chapitre-type/list/";
const LIST_PARTICIPANTS = "api/v1/chapitre/<pk>/list-participants/"
const LIST_PARTICIPANTS_PDF = "api/v1/chapitre/<pk>/list-participants-pdf/"
const LIST_PARTICIPANTS_ORDERED = "api/v1/chapitre/<pk>/list-participants-ordered"
const ADD_CHAPITRE = "api/v1/chapitre/add/";
const UPDATE_CHAPITRE = "api/v1/chapitre/<pk>/";
const MANAGE_PARTICIPANTS = "api/v1/chapitre/lock-multiple/"
const ADD_PARTICIPANT = "api/v1/chapitre/<pk>/add-participants/"

const GET_INTRONISATION_LIST = "api/v1/chapitre/<pk>/intronisation-list-pdf/"
const GET_POUVOIRS = "api/v1/chapitre/<pk>/pouvoir-pdf/"
const GET_LIVRE_DOR = "api/v1/chapitre/<pk>/golden-book-pdf/"
const GET_DIPLOMAS = "api/v1/chapitre/<pk>/diploma-pdf/"
const GET_CARDS = "api/v1/chapitre/<pk>/member-cards/"
const GET_PDF = "api/v1/chapitre/<pk>/generate-pdf/"
const EXPORT_LIST = "api/v1/chapitre/generate-list/"

const DELETE_PARTICIPANTS = "api/v1/chapitre/delete-participants/"
const MANAGE_PARTICIPATIONS = "api/v1/chapitre/is-participating/"
const UPDATE_PARTICIPANT = "api/v1/participant/<pk>/"
const GET_BAILLI_FROM_BAILLIAGE = "api/v1/bailliage/<pk>/get-bailli-delegue"

export default {
    exportList(payload) {
        ApiService.setHeader();
        return ApiService.post(EXPORT_LIST, payload).then(response => {
            return response.data;
        });
    },
    getChapitre(id) {
        ApiService.setHeader();
        return ApiService.get(GET_SPECIFIC_CHAPITRE.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    getChapitres(payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_CHAPITRES, payload).then(response => {
            return response.data;
        });
    },
    addChapitre(payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_CHAPITRE, payload).then(response => {
            return response.data;
        });
    },
    deleteChapitres(payload) {
        ApiService.setHeader();
        return ApiService.post(DELETE_CHAPITRES, payload).then(response => {
            return response.data;
        });
    },
    listRemoteMembers(id, payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_REMOTE.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    getChapitreTypes(payload) {
        ApiService.setHeader();
        return ApiService.post(CHAPITRE_TYPES, payload).then(response => {
            return response.data;
        });
    },
    listParticipants(id, payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_PARTICIPANTS.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    getIntronisationList(id, payload) {
        ApiService.setHeader();
        return ApiService.post(GET_INTRONISATION_LIST.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    getPouvoirs(id, payload) {
        ApiService.setHeader();
        return ApiService.post(GET_POUVOIRS.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    getLivreDor(id, payload) {
        ApiService.setHeader();
        return ApiService.post(GET_LIVRE_DOR.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    getPDF(id, payload) {
        ApiService.setHeader();
        return ApiService.post(GET_PDF.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    getCards(id, payload) {
        ApiService.setHeader();
        return ApiService.post(GET_CARDS.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    getDiplomas(id, payload) {
        ApiService.setHeader();
        return ApiService.post(GET_DIPLOMAS.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    listParticipantsPDF(id, payload) {
        ApiService.setHeader();
        return ApiService.post(LIST_PARTICIPANTS_PDF.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    listParticipantsOrdered(id) {
        ApiService.setHeader();
        return ApiService.get(LIST_PARTICIPANTS_ORDERED.replace("<pk>", id)).then(response => {
            return response.data;
        });
    },
    updateChapitre(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_CHAPITRE.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    manageChapitres(payload) {
        ApiService.setHeader();
        return ApiService.post(MANAGE_PARTICIPANTS, payload).then(response => {
            return response.data;
        });
    },
    manageParticipations(payload) {
        ApiService.setHeader();
        return ApiService.post(MANAGE_PARTICIPATIONS, payload).then(response => {
            return response.data;
        });
    },
    addParticipant(id, payload) {
        ApiService.setHeader();
        return ApiService.post(ADD_PARTICIPANT.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    deleteParticipants(payload) {
        ApiService.setHeader();
        return ApiService.post(DELETE_PARTICIPANTS, payload).then(response => {
            return response.data;
        });
    },
    updateParticipants(id, payload) {
        ApiService.setHeader();
        return ApiService.put(UPDATE_PARTICIPANT.replace("<pk>", id), payload).then(response => {
            return response.data;
        });
    },
    getBailliFromBailliage(id) {
        ApiService.setHeader();
        return ApiService.get(GET_BAILLI_FROM_BAILLIAGE.replace("<pk>", id)).then(response => {
            return response.data;
        });
    }
};
