
import { defineComponent, onMounted } from "vue";
import membersApi from "@/core/services/MembersApi";
import institutionApi from "@/core/services/InstitutionApi";
import promotionsApi from "@/core/services/PromotionsApi";
import chapitreApi from "@/core/services/ChapitresApi";
import { ElMessage } from "element-plus";

export default defineComponent({
    name: "filters",
    emits: ["filtersChanged", "queryChanged"],
    props: {
        displayFilters: Boolean,
        filtersToDisplay: Object,
        aFilters: Object,
    },
    components: {},
    data() {
        return {
            dispFilters: this.$props.displayFilters,
            filtersToShow: this.$props.filtersToDisplay as any,
            dropdownIsOpen: false,
            querySearch: "",
            // Filters
            remoteBailliages: [] as any,
            filters: {
                period: {
                    options: [
                        {
                            id: 0,
                            label: "After",
                            value: "after",
                        },
                        {
                            id: 1,
                            label: "At",
                            value: "at",
                        },
                        {
                            id: 2,
                            label: "Before",
                            value: "before",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_period: "at",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                grade: {
                    options: [] as any,
                    grades: [] as any,
                    grade_id: [],
                },
                commander: {
                    options: [] as any,
                    commander_id: "",
                    time_condition: "",
                    selected_commander: "",
                    selected_value_1: "",
                },
                medal: {
                    options: [] as any,
                    medal_id: "",
                    selected_medal: "",
                    selected_value_1: "",
                },
                title: {
                    options: [] as any,
                    title_id: "",
                    selected_value_1: "",
                },
                function: {
                    options: [] as any,
                    function_id: "",
                    selected_value_1: "",
                },
                establishment_type: {
                    options: [] as any,
                    institution_id: "",
                    selected_value_1: "",
                },
                credit_card: {
                    options: [] as any,
                    credit_card_id: [] as any,
                    selected_values: [],
                },
                food_style: {
                    options: [] as any,
                    food_style_id: [] as any,
                    selected_values: [],
                },
                activity_sector: {
                    options: [] as any,
                    activity_sector_id: "",
                    selected_value_1: "",
                },
                bailliage: {
                    nat_options: [] as any,
                    prov_options: [] as any,
                    reg_options: [] as any,
                    national: [],
                    national_id: [],
                    provincial: [],
                    provincial_id: [],
                    regional: [],
                    regional_id: [],
                },
                age: {
                    options: [
                        {
                            id: 0,
                            label: "Equal to",
                            value: "equal to",
                        },
                        {
                            id: 1,
                            label: "More than",
                            value: "more than",
                        },
                        {
                            id: 2,
                            label: "Less than",
                            value: "less than",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_age: "equal to",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                memberTypes: {
                    options: [
                        {
                            id: 0,
                            label: "Amateur",
                            value: "amateur",
                        },
                        {
                            id: 1,
                            label: "Pro",
                            value: "pro",
                        },
                        {
                            id: 2,
                            label: "OMGD",
                            value: "omgd",
                        },
                        {
                            id: 3,
                            label: "Jeune sommelier",
                            value: "sommelier",
                        },
                        {
                            id: 4,
                            label: "Jeune chef rôtisseur",
                            value: "chef",
                        },
                    ],
                    selectedMemberTypes: [],
                    is_amateur: false,
                    is_pro: false,
                    is_omgd: false,
                    is_young_clerk: false,
                    is_young_sommelier: false,
                },

                price_category_promotion: {
                    options: [] as any,
                    price_cat_id: [] as any,
                    selected_values: [],
                },
                price_category_due: {
                    options: [] as any,
                    price_cat_id: [] as any,
                    selected_values: [],
                },
                type_due: {
                    options: [] as any,
                    due_type_id: [] as any,
                    selected_values: [] as any,
                },
                relevant_due_year: {
                    options: [
                        {
                            id: 0,
                            label: "After",
                            value: "after",
                        },
                        {
                            id: 1,
                            label: "At",
                            value: "at",
                        },
                        {
                            id: 2,
                            label: "Before",
                            value: "before",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_period: "at",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                type_chapitre: {
                    options: [] as any,
                    chapitre_type_id: [] as any,
                    selected_values: [] as any,
                },
                amount_accr: {
                    options: [
                        {
                            id: 0,
                            label: "Equal to",
                            value: "equal to",
                        },
                        {
                            id: 1,
                            label: "More than",
                            value: "more than",
                        },
                        {
                            id: 2,
                            label: "Less than",
                            value: "less than",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_amount: "equal to",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                type_accr: "",
                biller_period: {
                    options: [
                        {
                            id: 0,
                            label: "After",
                            value: "after",
                        },
                        {
                            id: 1,
                            label: "At",
                            value: "at",
                        },
                        {
                            id: 2,
                            label: "Before",
                            value: "before",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_period: "at",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                biller_initial_balance: {
                    options: [
                        {
                            id: 0,
                            label: "Equal to",
                            value: "equal to",
                        },
                        {
                            id: 1,
                            label: "More than",
                            value: "more than",
                        },
                        {
                            id: 2,
                            label: "Less than",
                            value: "less than",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_balance: "equal to",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                biller_actual_balance: {
                    options: [
                        {
                            id: 0,
                            label: "Equal to",
                            value: "equal to",
                        },
                        {
                            id: 1,
                            label: "More than",
                            value: "more than",
                        },
                        {
                            id: 2,
                            label: "Less than",
                            value: "less than",
                        },
                        {
                            id: 3,
                            label: "Between",
                            value: "between",
                        },
                    ],
                    selected_balance: "equal to",
                    selected_value_1: "",
                    selected_value_2: "",
                },
                sex: "",
                postal_code: "",
                state: "",
                country: "",
                other_association: "",
                plaque: "" as any,
                dues: "" as any,
                stars: 0,
                pouvoirs: "" as any,
            },
            activeFilters: this.$props.aFilters as any,
            payload: {} as any,
            filterId: Math.floor(Math.random() * 1000),
        };
    },
    setup() {
        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return { debounce: createDebounce() };
    },
    mounted() {
        this.getFields();
    },
    methods: {
        getFields() {
            membersApi.getBailliages().then((res: any) => {
                res.data.map((bailliage: any) => {
                    if (
                        bailliage.type === "National" ||
                        bailliage.type === "national"
                    )
                        this.filters.bailliage.nat_options.push(bailliage);
                    else if (
                        bailliage.type === "Provincial" ||
                        bailliage.type === "provincial"
                    )
                        this.filters.bailliage.prov_options.push(bailliage);
                    else if (
                        bailliage.type === "Regional" ||
                        bailliage.type === "regional"
                    )
                        this.filters.bailliage.reg_options.push(bailliage);
                });
                this.filters.bailliage.nat_options.sort((a: any, b: any) =>
                    a.label > b.label ? 1 : -1
                );
                this.filters.bailliage.prov_options.sort((a: any, b: any) =>
                    a.label > b.label ? 1 : -1
                );
                this.filters.bailliage.reg_options.sort((a: any, b: any) =>
                    a.label > b.label ? 1 : -1
                );
            });
            membersApi
                .getCommanders({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.filters.commander.options = res.data.object_list;
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            membersApi
                .getGrades({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.filters.grade.options = res.data.object_list.sort(
                        (a: any, b: any) => (a.label > b.label ? 1 : -1)
                    );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            membersApi
                .getMedals({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.filters.medal.options = res.data.object_list;
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            membersApi
                .getFunctions({ p: 1, presult: 9999 })
                .then((res) => {
                    this.filters.function.options = res.data.object_list.sort(
                        (a: any, b: any) => (a.label > b.label ? 1 : -1)
                    );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            membersApi
                .getActivitySectors({ p: 1, presult: 9999 })
                .then((res) => {
                    this.filters.activity_sector.options =
                        res.data.object_list.sort((a: any, b: any) =>
                            a.label > b.label ? 1 : -1
                        );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            membersApi
                .getTitles({ p: 1, presult: 9999 })
                .then((res) => {
                    this.filters.title.options = res.data.object_list.sort(
                        (a: any, b: any) => (a.label > b.label ? 1 : -1)
                    );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            membersApi
                .getInstitutionTypes({ p: 1, presult: 9999 })
                .then((res) => {
                    this.filters.establishment_type.options =
                        res.data.object_list.sort((a: any, b: any) =>
                            a.label > b.label ? 1 : -1
                        );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            institutionApi
                .getFoodStyles({ p: 1, presult: 9999 })
                .then((res) => {
                    this.filters.food_style.options = res.data.object_list.sort(
                        (a: any, b: any) => (a.label > b.label ? 1 : -1)
                    );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            institutionApi
                .getCreditCards({ p: 1, presult: 9999 })
                .then((res) => {
                    this.filters.credit_card.options =
                        res.data.object_list.sort((a: any, b: any) =>
                            a.label > b.label ? 1 : -1
                        );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            promotionsApi
                .getPromotionsPriceCategories({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.filters.price_category_promotion.options =
                        res.data.object_list.sort((a: any, b: any) =>
                            a.label > b.label ? 1 : -1
                        );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            membersApi
                .getPriceCategories({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.filters.price_category_due.options =
                        res.data.object_list.sort((a: any, b: any) =>
                            a.label > b.label ? 1 : -1
                        );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            membersApi
                .getDuesTypes({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.filters.type_due.options = res.data.object_list.sort(
                        (a: any, b: any) => (a.label > b.label ? 1 : -1)
                    );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
            chapitreApi
                .getChapitreTypes({ p: 1, presult: 9999 })
                .then((res: any) => {
                    this.filters.type_chapitre.options =
                        res.data.object_list.sort((a: any, b: any) =>
                            a.label > b.label ? 1 : -1
                        );
                })
                .catch((err) => {
                    console.log("disconnected from server");
                });
        },
        resetFilters() {
            this.activeFilters = [];
            this.payload = {};

            this.filters.age.selected_age = "equal to";
            this.filters.age.selected_value_1 = "";
            this.filters.age.selected_value_2 = "";

            this.filters.amount_accr.selected_amount = "equal to";
            this.filters.amount_accr.selected_value_1 = "";
            this.filters.amount_accr.selected_value_2 = "";

            this.filters.period.selected_period = "at";
            this.filters.period.selected_value_1 = "";
            this.filters.period.selected_value_2 = "";

            this.filters.biller_period.selected_period = "at";
            this.filters.biller_period.selected_value_1 = "";
            this.filters.biller_period.selected_value_2 = "";

            this.filters.relevant_due_year.selected_period = "equal to";
            this.filters.relevant_due_year.selected_value_1 = "";
            this.filters.relevant_due_year.selected_value_2 = "";

            this.filters.biller_initial_balance.selected_balance = "equal to";
            this.filters.biller_initial_balance.selected_value_1 = "";
            this.filters.biller_initial_balance.selected_value_2 = "";

            this.filters.biller_actual_balance.selected_balance = "equal to";
            this.filters.biller_actual_balance.selected_value_1 = "";
            this.filters.biller_actual_balance.selected_value_2 = "";

            this.filters.stars = 0;

            this.filters.grade.grades = [];

            this.filters.bailliage.national = [];
            this.filters.bailliage.national_id = [];
            this.filters.bailliage.provincial = [];
            this.filters.bailliage.provincial_id = [];
            this.filters.bailliage.regional = [];
            this.filters.bailliage.regional_id = [];

            this.filters.postal_code = "";

            this.filters.state = "";

            this.filters.country = "";

            this.filters.other_association = "";

            this.filters.memberTypes.selectedMemberTypes = [];
            this.filters.memberTypes.is_amateur = false;
            this.filters.memberTypes.is_pro = false;
            this.filters.memberTypes.is_omgd = false;
            this.filters.memberTypes.is_young_sommelier = false;
            this.filters.memberTypes.is_young_clerk = false;

            this.filters.activity_sector.selected_value_1 = "";

            this.filters.establishment_type.selected_value_1 = "";

            this.filters.food_style.selected_values = [];
            this.filters.food_style.food_style_id = [];

            this.filters.price_category_promotion.selected_values = [];
            this.filters.price_category_promotion.price_cat_id = [];

            this.filters.type_due.selected_values = [];
            this.filters.type_due.due_type_id = [];

            this.filters.type_chapitre.selected_values = [];
            this.filters.type_chapitre.chapitre_type_id = [];

            this.filters.price_category_due.selected_values = [];
            this.filters.price_category_due.price_cat_id = [];

            this.filters.credit_card.selected_values = [];
            this.filters.credit_card.credit_card_id = [];

            this.filters.medal.selected_medal = "";
            this.filters.medal.selected_value_1 = "";

            this.filters.title.selected_value_1 = "";

            this.filters.function.selected_value_1 = "";

            this.filters.dues = "";

            this.filters.sex = "";

            this.filters.plaque = "";

            this.$emit("filtersChanged", [this.payload, this.activeFilters]);
        },
        // Get ready to go to hell
        applyFilters() {
            // Reset the filters
            this.activeFilters = [];
            //this.payload = {};

            // Close the dropdown
            var closeFilters = document.getElementById(
                "filter" + this.filterId
            ) as any;
            closeFilters.click();

            // Period filter
            if (this.filters.period.selected_value_1) {
                var periodLabel =
                    this.filters.period.selected_period === "between"
                        ? "Period is between <b>" +
                          this.filters.period.selected_value_1 +
                          "</b> and <b>" +
                          this.filters.period.selected_value_2 +
                          "</b>"
                        : "Period is " +
                          this.filters.period.selected_period +
                          " <b>" +
                          this.filters.period.selected_value_1 +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: periodLabel,
                    key: "period",
                    values: {
                        type: this.filters.period.selected_period,
                        values:
                            this.filters.period.selected_period === "between"
                                ? [
                                      this.filters.period.selected_value_1,
                                      this.filters.period.selected_value_2,
                                  ]
                                : [this.filters.period.selected_value_1],
                    },
                });
            }

            // Due relevant year filter
            if (this.filters.relevant_due_year.selected_value_1) {
                var relevantDueLabel =
                    this.filters.relevant_due_year.selected_period === "between"
                        ? "Relevant year is between <b>" +
                          this.filters.relevant_due_year.selected_value_1 +
                          "</b> and <b>" +
                          this.filters.relevant_due_year.selected_value_2 +
                          "</b>"
                        : "Relevant year is " +
                          this.filters.relevant_due_year.selected_period +
                          " <b>" +
                          this.filters.relevant_due_year.selected_value_1 +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: relevantDueLabel,
                    key: "relevant_year",
                    values: {
                        type: this.filters.relevant_due_year.selected_period,
                        values:
                            this.filters.relevant_due_year.selected_period ===
                            "between"
                                ? [
                                      this.filters.relevant_due_year
                                          .selected_value_1,
                                      this.filters.relevant_due_year
                                          .selected_value_2,
                                  ]
                                : [
                                      this.filters.relevant_due_year
                                          .selected_value_1,
                                  ],
                    },
                });
            }

            // Biller relevant year filter
            if (this.filters.biller_period.selected_value_1) {
                var relevantBillerLabel =
                    this.filters.biller_period.selected_period === "between"
                        ? "Relevant year is between <b>" +
                          this.filters.biller_period.selected_value_1 +
                          "</b> and <b>" +
                          this.filters.biller_period.selected_value_2 +
                          "</b>"
                        : "Relevant year is " +
                          this.filters.period.selected_period +
                          " <b>" +
                          this.filters.biller_period.selected_value_1 +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: relevantBillerLabel,
                    key: "relevant_year",
                    values: {
                        type: this.filters.biller_period.selected_period,
                        values:
                            this.filters.biller_period.selected_period ===
                            "between"
                                ? [
                                      this.filters.biller_period
                                          .selected_value_1,
                                      this.filters.biller_period
                                          .selected_value_2,
                                  ]
                                : [this.filters.biller_period.selected_value_1],
                    },
                });
            }

            // Gender filter
            if (this.filters.sex) {
                var genderLabel =
                    this.filters.sex === "M"
                        ? "Gender is <b>Male</b>"
                        : "Gender is <b>Female</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: genderLabel,
                    key: "gender",
                    values: [this.filters.sex],
                });
            }

            // Pouvoirs filter
            if (this.filters.pouvoirs) {
                var pouvoirsLabel =
                    this.filters.pouvoirs === "yes"
                        ? "Pouvoirs are <b>signed</b>"
                        : "Pouvoirs are <b>not signed</b>";
                this.filters.pouvoirs === "yes"
                    ? (this.filters.pouvoirs = true)
                    : (this.filters.pouvoirs = false);
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: pouvoirsLabel,
                    key: "is_pouvoir",
                    values: [this.filters.pouvoirs],
                });
            }

            // Plaque filter
            if (this.filters.plaque) {
                var plaqueLabel =
                    this.filters.plaque === "true"
                        ? "Chaine plaque <b> is displayed</b>"
                        : "Chaine plaque <b> is not displayed</b>";
                this.filters.plaque === "true"
                    ? (this.filters.plaque = true)
                    : (this.filters.plaque = false);
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: plaqueLabel,
                    key: "chaine_plaque_displayed",
                    values: [this.filters.plaque],
                });
            }

            // Age filter
            if (this.filters.age.selected_value_1) {
                var ageLabel =
                    this.filters.age.selected_age === "between"
                        ? "Age is between <b>" +
                          this.filters.age.selected_value_1 +
                          " years old </b> and <b>" +
                          this.filters.age.selected_value_2 +
                          " years old</b>"
                        : "Age is " +
                          this.filters.age.selected_age +
                          " <b>" +
                          this.filters.age.selected_value_1 +
                          " years old</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: ageLabel,
                    key: "age",
                    values: {
                        type: this.filters.age.selected_age,
                        values:
                            this.filters.age.selected_age === "between"
                                ? [
                                      this.filters.age.selected_value_1,
                                      this.filters.age.selected_value_2,
                                  ]
                                : [this.filters.age.selected_value_1],
                    },
                });
            }

            // Biller initial balance filter
            if (this.filters.biller_initial_balance.selected_value_1) {
                var initialBalanceLabel =
                    this.filters.biller_initial_balance.selected_balance ===
                    "between"
                        ? "Initial balance is between <b>" +
                          this.filters.biller_initial_balance.selected_value_1 +
                          ",00 €</b> and <b>" +
                          this.filters.biller_initial_balance.selected_value_2 +
                          ",00 €</b>"
                        : "Initial balance is " +
                          this.filters.biller_initial_balance.selected_balance +
                          " <b>" +
                          this.filters.biller_initial_balance.selected_value_1 +
                          ",00 €</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: initialBalanceLabel,
                    key: "initial_balance",
                    values: {
                        type: this.filters.biller_initial_balance
                            .selected_balance,
                        values:
                            this.filters.biller_initial_balance
                                .selected_balance === "between"
                                ? [
                                      this.filters.biller_initial_balance
                                          .selected_value_1,
                                      this.filters.biller_initial_balance
                                          .selected_value_2,
                                  ]
                                : [
                                      this.filters.biller_initial_balance
                                          .selected_value_1,
                                  ],
                    },
                });
            }

            // Biller actual balance filter
            if (this.filters.biller_actual_balance.selected_value_1) {
                var actualBalanceLabel =
                    this.filters.biller_actual_balance.selected_balance ===
                    "between"
                        ? "Current balance is between <b>" +
                          this.filters.biller_actual_balance.selected_value_1 +
                          ",00 €</b> and <b>" +
                          this.filters.biller_actual_balance.selected_value_2 +
                          ",00 €</b>"
                        : "Current balance is " +
                          this.filters.biller_actual_balance.selected_balance +
                          " <b>" +
                          this.filters.biller_actual_balance.selected_value_1 +
                          ",00 €</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: actualBalanceLabel,
                    key: "current_balance",
                    values: {
                        type: this.filters.biller_actual_balance
                            .selected_balance,
                        values:
                            this.filters.biller_actual_balance
                                .selected_balance === "between"
                                ? [
                                      this.filters.biller_actual_balance
                                          .selected_value_1,
                                      this.filters.biller_actual_balance
                                          .selected_value_2,
                                  ]
                                : [
                                      this.filters.biller_actual_balance
                                          .selected_value_1,
                                  ],
                    },
                });
            }

            // ACCR amount filter
            if (this.filters.amount_accr.selected_value_1) {
                var amountLabel =
                    this.filters.amount_accr.selected_amount === "between"
                        ? "Donation is between <b>" +
                          this.filters.amount_accr.selected_value_1 +
                          ",00 € </b> and <b>" +
                          this.filters.amount_accr.selected_value_2 +
                          ",00 €</b>"
                        : "Age is " +
                          this.filters.amount_accr.selected_amount +
                          " <b>" +
                          this.filters.amount_accr.selected_value_1 +
                          " years old</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: amountLabel,
                    key: "amount",
                    values: {
                        type: this.filters.amount_accr.selected_amount,
                        values:
                            this.filters.amount_accr.selected_amount ===
                            "between"
                                ? [
                                      this.filters.amount_accr.selected_value_1,
                                      this.filters.amount_accr.selected_value_2,
                                  ]
                                : [this.filters.amount_accr.selected_value_1],
                    },
                });
            }

            // Bailliages filter
            // National
            if (this.filters.bailliage.national.length > 0) {
                var natBailliageLabel =
                    this.filters.bailliage.national.length > 1
                        ? "Multiple national bailliages selected"
                        : "National bailliage is <b>" +
                          this.filters.bailliage.national[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: natBailliageLabel,
                    key: "national_bailliages",
                    values: [this.filters.bailliage.national_id],
                });
            }

            // Provincial
            if (this.filters.bailliage.provincial.length > 0) {
                var provBailliageLabel =
                    this.filters.bailliage.provincial.length > 1
                        ? "Multiple provincial bailliages selected"
                        : "Provincial bailliage is <b>" +
                          this.filters.bailliage.provincial[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: provBailliageLabel,
                    key: "provincial_bailliages",
                    values: [this.filters.bailliage.provincial_id],
                });
            }

            // Regional
            if (this.filters.bailliage.regional.length > 0) {
                var regBailliageLabel =
                    this.filters.bailliage.regional.length > 1
                        ? "Multiple regional bailliages selected"
                        : "Regional bailliage is <b>" +
                          this.filters.bailliage.regional[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: regBailliageLabel,
                    key: "regional_bailliages",
                    values: [this.filters.bailliage.regional_id],
                });
            }

            // Grades filter
            if (this.filters.grade.grades.length > 0) {
                var gradeLabel =
                    this.filters.grade.grades.length > 1
                        ? "Multiple grades selected"
                        : "Grade is <b>" +
                          this.filters.grade.grades[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: gradeLabel,
                    key: "grades",
                    values: [this.filters.grade.grades],
                });
            }

            // Commanders
            if (this.filters.commander.selected_commander) {
                var commanderLabel =
                    "Commandeur level is <b>" +
                    this.filters.commander.selected_commander +
                    " years</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: commanderLabel,
                    key: "commander",
                    values: {
                        type: this.filters.commander.commander_id,
                    },
                });
            }

            // Medals
            if (this.filters.medal.selected_medal) {
                var medalLabel =
                    "Has a <b>" +
                    this.filters.medal.selected_medal +
                    "</b> medal";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: medalLabel,
                    key: "medal",
                    values: {
                        type: this.filters.medal.medal_id,
                    },
                });
            }

            // Titles
            if (this.filters.title.selected_value_1) {
                var titleLabel =
                    "Title is <b>" +
                    this.filters.title.selected_value_1 +
                    "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: titleLabel,
                    key: "title",
                    values: [this.filters.title.title_id],
                });
            }

            // Other associations
            if (this.filters.other_association) {
                var assoLabel =
                    "Other association: <b>" +
                    this.filters.other_association +
                    "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: assoLabel,
                    key: "other_associations",
                    values: [this.filters.other_association],
                });
            }

            // Functions
            if (this.filters.function.selected_value_1) {
                var functionLabel =
                    "Function is <b>" +
                    this.filters.function.selected_value_1 +
                    "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: functionLabel,
                    key: "function",
                    values: [this.filters.function.function_id],
                });
            }

            // Country
            if (this.filters.country) {
                var countryLabel =
                    "Country is <b>" + this.filters.country + "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: countryLabel,
                    key: "country",
                    values: [this.filters.country],
                });
            }

            // State
            if (this.filters.state) {
                var stateLabel = "State is <b>" + this.filters.state + "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: stateLabel,
                    key: "state",
                    values: [this.filters.state],
                });
            }

            // Postal code
            if (this.filters.postal_code) {
                var postalCodeLabel =
                    "Postal code is <b>" + this.filters.postal_code + "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: postalCodeLabel,
                    key: "postal_code",
                    values: [this.filters.postal_code],
                });
            }

            // Activity sector
            if (this.filters.activity_sector.selected_value_1) {
                var activitySectorLabel =
                    "Activity sector is <b>" +
                    this.filters.activity_sector.selected_value_1 +
                    "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: activitySectorLabel,
                    key: "activity_sector",
                    values: [this.filters.activity_sector.activity_sector_id],
                });
            }

            // Type of establishment
            if (this.filters.establishment_type.selected_value_1) {
                var establishmentLabel =
                    "Establishment type is <b>" +
                    this.filters.establishment_type.selected_value_1 +
                    "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: establishmentLabel,
                    key: "institution_type",
                    values: [this.filters.establishment_type.institution_id],
                });
            }

            // Food Style
            if (this.filters.food_style.selected_values.length > 0) {
                var foodLabel =
                    this.filters.food_style.selected_values.length > 1
                        ? "Multiple food styles selected"
                        : "Food style is <b>" +
                          this.filters.food_style.selected_values[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: foodLabel,
                    key: "food_styles",
                    values: [this.filters.food_style.food_style_id],
                });
            }

            // Stars
            if (this.filters.stars) {
                var starsLabel = "Has <b>" + this.filters.stars + " stars</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: starsLabel,
                    key: "stars",
                    values: [this.filters.stars],
                });
            }

            // Credit card accepted
            if (this.filters.credit_card.selected_values.length > 0) {
                var creditCardLabel =
                    this.filters.food_style.selected_values.length > 1
                        ? "Multiple credit cards selected"
                        : "Credit card is <b>" +
                          this.filters.credit_card.selected_values[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: creditCardLabel,
                    key: "credit_card",
                    values: [this.filters.credit_card.credit_card_id],
                });
            }

            // Member types filter
            if (this.filters.memberTypes.selectedMemberTypes.length > 0) {
                var memberTypeLabel =
                    this.filters.memberTypes.selectedMemberTypes.length > 1
                        ? "Multiple member types selected"
                        : "Member type is <b>" +
                          this.filters.memberTypes.selectedMemberTypes[0] +
                          "</b>";

                this.filters.memberTypes.selectedMemberTypes.map(
                    (memberType: any) => {
                        switch (memberType) {
                            case "Amateur":
                                this.filters.memberTypes.is_amateur = true;
                                break;
                            case "Pro":
                                this.filters.memberTypes.is_pro = true;
                                break;
                            case "OMGD":
                                this.filters.memberTypes.is_omgd = true;
                                break;
                            case "Jeune sommelier":
                                this.filters.memberTypes.is_young_sommelier =
                                    true;
                                break;
                            case "Jeune chef rôtisseur":
                                this.filters.memberTypes.is_young_clerk = true;
                                break;
                        }
                    }
                );
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: memberTypeLabel,
                    key: "member_types",
                    values: {
                        is_amateur: this.filters.memberTypes.is_amateur,
                        is_pro: this.filters.memberTypes.is_pro,
                        is_omgd: this.filters.memberTypes.is_omgd,
                        is_young_clerk: this.filters.memberTypes.is_young_clerk,
                        is_young_sommelier:
                            this.filters.memberTypes.is_young_sommelier,
                    },
                });
            }

            // Dues filter
            if (this.filters.dues) {
                var duesLabel =
                    this.filters.dues === "false"
                        ? "Dues are <b>late</b>"
                        : "Dues are <b>up-to-date</b>";
                this.filters.dues === "false"
                    ? (this.filters.dues = false)
                    : (this.filters.dues = true);
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: duesLabel,
                    key: "dues",
                    values: [this.filters.dues],
                });
            }

            // Price category - Promotion
            if (
                this.filters.price_category_promotion.selected_values.length > 0
            ) {
                var priceCatPromLabel =
                    this.filters.price_category_promotion.selected_values
                        .length > 1
                        ? "Multiple price categories selected"
                        : "Price category is <b>" +
                          this.filters.price_category_promotion
                              .selected_values[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: priceCatPromLabel,
                    key: "price_category",
                    values: [
                        this.filters.price_category_promotion.price_cat_id,
                    ],
                });
            }

            // Price category - Due
            if (this.filters.price_category_due.selected_values.length > 0) {
                var priceCatDueLabel =
                    this.filters.price_category_due.selected_values.length > 1
                        ? "Multiple price categories selected"
                        : "Price category is <b>" +
                          this.filters.price_category_due.selected_values[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: priceCatDueLabel,
                    key: "price_category",
                    values: [this.filters.price_category_due.price_cat_id],
                });
            }

            // Due type filter
            if (this.filters.type_due.selected_values.length > 0) {
                var dueTypeLabel =
                    this.filters.type_due.selected_values.length > 1
                        ? "Multiple due types selected"
                        : "Due type is <b>" +
                          this.filters.type_due.selected_values[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: dueTypeLabel,
                    key: "type",
                    values: [this.filters.type_due.due_type_id],
                });
            }

            // Chapitre type filter
            if (this.filters.type_chapitre.selected_values.length > 0) {
                var chapitreTypeLabel =
                    this.filters.type_chapitre.selected_values.length > 1
                        ? "Multiple chapitre types selected"
                        : "Chapitre type is <b>" +
                          this.filters.type_chapitre.selected_values[0] +
                          "</b>";
                this.activeFilters.push({
                    id: this.activeFilters.length,
                    label: chapitreTypeLabel,
                    key: "type",
                    values: [this.filters.type_chapitre.chapitre_type_id],
                });
            }

            // Build filters payload
            this.activeFilters.map((filter: any) => {
                this.payload = {
                    ...this.payload,
                    [filter.key]:
                        Object.prototype.toString.call(filter.values) ===
                        "[object Object]"
                            ? filter.values
                            : filter.values.length > 1
                            ? filter.values
                            : filter.values[0],
                };
            });

            if (this.activeFilters && this.activeFilters.length === 0) {
                ElMessage({
                    type: "info",
                    message: "No filter selected.",
                });
            } else {
                ElMessage({
                    type: "success",
                    message: "Filters applied.",
                });
            }

            this.$emit("filtersChanged", [this.payload, this.activeFilters]);
        },
        handleQuery() {
            this.$emit("queryChanged", this.querySearch);
        },
    },
});
